import { Anchor, Avatar, Box, Button, Heading } from 'grommet'
import { Login } from 'grommet-icons'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
)

const Nav = ({ user, userLoading, signInWithGoogle }) => {
  const [userPhoto, setUserPhoto] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    user && setUserPhoto(user.photoURL)
  }, [user && user.photoURL])

  let menuIcons = <Button icon={<Login />} />

  if (!userLoading) {
    menuIcons = (<>
      { user ?
        <Avatar src={userPhoto} onClick={() => navigate('/me')} /> : 
        <Button icon={<Login />} tip={{
          content: 'Sign in with Google',
          plain: false,
        }} onClick={signInWithGoogle} />
      }
    </>)
  }

  return(
    <AppBar>
      <Heading level='3' margin='none'><Anchor href='/' label='World Sankirtana Report' /></Heading>
      <Box direction='row'>
        { menuIcons }
      </Box>
    </AppBar>
  )
}

export default Nav