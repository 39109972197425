import { useEffect, useState } from 'react'
import { FormField, TextInput, Button, Box, Card, CardBody, CardFooter, CardHeader, Select, Text, DateInput, Table, TableBody, TableCell, TableRow, Tag, CheckBox } from 'grommet'
import { AddCircle, SubtractCircle, Clear as ClearIcon, Search as SearchIcon } from 'grommet-icons'

import firebase from 'firebase/compat/app'

import Notify from './Notify'

const SankirtanaAdvForm = ({ user, firestore }) => {
  const { uid } = user
  const bookLangs = ['English', 'Hindi', 'Marathi', 'Gujarati', 'Kannada', 'Tamil', 'Bengali', 'Telugu', 'Others']

  const [notify, setNotify] = useState(false)
  const [bookLang, setBookLang] = useState('English')
  const [date, setDate] = useState(new Date().toISOString())

  const [country, setCountry] = useState('')
  const [hkcenter, setHKCenter] = useState('')
  const [temple, setTemple] = useState('')
  const [team, setTeam] = useState(false)
  const [profileName, setProfileName] = useState('')

  const [books, setBooks] = useState([])
  const [displayBooks, setDisplayBooks] = useState()
  const [selectedBooks, setSelectedBooks] = useState([])
  const [displayLangs, setDisplayLangs] = useState(bookLangs)
  const [submitDisable, setSubmitDisable] = useState(false)
  const [subsidized, setSusidized] = useState(false)

  useEffect(() => {
    let isCancelled = false
    firestore.collection('profiles').doc(uid).get().then(profile => {
      if (!isCancelled) {
        const profileData = profile.data()
        setCountry(profileData.country)
        setHKCenter(profileData.hkcenter)
        setTemple(profileData.temple)
        setTeam(profileData.team)
        setProfileName(profileData.profileName)
      }
    })

    firestore.collection('books').get().then(books => {
      if (!isCancelled) {
        setBooks(books.docs.map(snap => snap.data()))
        setDisplayBooks(books.docs.map(snap => snap.data()))
      }
    })

    return () => {
      isCancelled = true
    }
  }, [])

  const saveEntry = async(e) => {
    e.preventDefault()

    // verification
    if (!date) {
      setNotify({ status: 'critical', title:'Date error', message: 'Date is required Required' })
      return
    }
    if (new Date(date) > new Date()) {
      setNotify({ status: 'critical', title:'Date error', message: 'Not allowed for a future date' })
      return
    }
    if (new Date(date) < new Date('2022-11-01')) {
      setNotify({ status: 'critical', title:'Date error', message: 'Entries before 01/11/2022 are not allowed' })
      return
    }

    if (!bookLang) {
      setNotify({ status: 'critical', title:'Language error', message: 'Language field can\'t be left empty' })
      return
    }

    setSubmitDisable(true)

    let booksObject = {}
    let categories = {}
    let totalScore = 0
    Object.keys(selectedBooks).filter(bookname => !!selectedBooks[bookname]).forEach(bookname => {
      booksObject[bookname] = selectedBooks[bookname]
      // extract category.
      let bookcat = books.find(book => (book.lang === bookLang && book.name === bookname))
      if (!bookcat) {
        bookcat = books.find(book => (book.name === bookname))
      }
      if (!categories[bookcat.category]) {
        categories[bookcat.category] = selectedBooks[bookname] * (bookcat.multiplier || 1)
      } else {
        categories[bookcat.category] += selectedBooks[bookname] * (bookcat.multiplier || 1)
      }
      totalScore += parseFloat(bookcat.score) * (bookcat.multiplier || 1) * selectedBooks[bookname]
    })

    if (!temple || !hkcenter || !country) {
      setNotify({ status: 'critical', title:'Unable to proceed', message: 'Please complete your profile' })
      return
    }

    const sankirtanaRef = firestore.collection('sankirtana')
    try {
      await sankirtanaRef.add({
        uid,
        team,
        country,
        temple,
        hkcenter,
        profileName,
        score: totalScore,
        categories: categories,
        booksObject: booksObject,
        subsidized,
        bookLang,
        status: "unapproved",
        createdAt: firebase.firestore.Timestamp.fromDate(new Date(date)),
        enteredAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      setNotify({ status: 'normal', title:'Hare Krishna🙏', message: 'Sankirtana data was added' })
      setSelectedBooks({})
      setBookLang('')
      setDate(new Date().toISOString())
      setSubmitDisable(false)
    } catch (error) {
      console.log(error)
      setNotify({ status: 'critical', title:'Something went wrong', message: 'Try again later, or inform contact@hkmmumbai.org' })
    }
  }

  const selectedArray = Object.keys(selectedBooks).filter(bookname => !!selectedBooks[bookname])

  return (
    <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', bottom: 'large' }}>
      {notify && <Notify title={notify.title} message={notify.message} status={notify.status} handleClose={() => { setNotify(false) }} />}

      <CardHeader pad='medium' background={'light-2'} direction='row' align='left'>
        <Text weight='bold' size='large'>New entry</Text>
      </CardHeader>
      {/* <CardBody pad='medium'>
        <Text>New entries are frozen till we announce the results.</Text>
      </CardBody> */}
      <CardBody pad='medium'>
        <Box direction='row' justify='between' margin={{ bottom: 'medium' }}>
          <FormField margin={{ right: 'small' }}>
            <Select
              placeholder='Select language'
              options={displayLangs}
              value={bookLang}
              onChange={({ option }) => setBookLang(option)}
              onSearch={key => {
                key = key.trim()
                if (!key) {
                  setDisplayLangs(bookLangs)
                }
                setDisplayLangs(bookLangs.filter(lang => lang.toUpperCase().match(key.toUpperCase())))
              }}
              clear={{ label: 'Clear' }}
            />
          </FormField>
          <FormField margin={{ left: 'small' }}>
            <DateInput
              format="dd/mm/yyyy"
              defaultValue={date}
              value={date}
              onChange={({ value }) => {
                setDate(value)
              }}
              
            />
          </FormField>
        </Box>

        {selectedArray.length === 0 && (<>
          <Text color={'text-xweak'} margin={{ bottom: 'medium' }}>No book selected. Please select some from the list below.</Text>
        </>)}
        {selectedArray.length > 0 && (<>
          <Box direction='row' gap='xsmall' wrap={true}>
            {selectedArray.map((bookname, index) => (
              <Tag key={index} name={bookname}
                value={selectedBooks[bookname]}
                onRemove={() => {
                  selectedBooks[bookname] = 0
                  setSelectedBooks({...selectedBooks})
                }}
                size={'medium'}
                as={'span'}
                background={'light-2'}
                margin={{ bottom: 'small' }}
              />
            ))}
          </Box>
          <Box direction='row' gap='medium' justify='start'>
            <CheckBox
              checked={subsidized}
              label="subsidized?"
              onChange={(event) => setSusidized(event.target.checked)}
            />
            <Button primary disabled={submitDisable} size='small' label='Submit' margin={{ vertical: 'small' }} onClick={saveEntry} />
          </Box>
        </>)}

        <FormField>
          <TextInput onChange={(e) => {
            const key = e.target.value && e.target.value.trim()
            if (!key) {
              setDisplayBooks(books)
            }
            setDisplayBooks(books.filter(book => book.name.toUpperCase().match(key.toUpperCase())))
          }} icon={<SearchIcon />} placeholder='Search books' />
        </FormField>

        {displayBooks && <Table><TableBody>
          {displayBooks.map(book => book.name)
            .sort()
            .filter((value,index,self) => self.indexOf(value) === index)
            .map((book, index) => (
            <TableRow style={{ background: (index % 2) ? '': '#F2F2F2' }} key={index}>
              <TableCell>
                <Text size={'medium'} color={'text-strong'}>{book}</Text>
              </TableCell>

              <TableCell align='right'>
                <Box direction='row' justify='end'>

                  <Button size='small' icon={<SubtractCircle color='text-xweak' />} onClick={() => {
                    if (!selectedBooks[book]) {
                      selectedBooks[book] = 0
                    } else {
                      selectedBooks[book] -= 1
                    }
                    setSelectedBooks({...selectedBooks})
                  }} />

                  <FormField style={{  }}>
                    <TextInput alignSelf='center' margin='small'
                      style={{ width: '40px', textAlign: 'center', padding: '4px 0', margin: '10px 0 0', borderBottom: 'solid 0px' }}
                      value={selectedBooks[book] || 0}
                      onChange={e => {
                        const count = parseInt(e.target.value) || 0
                        if (count > 999) {
                          count = 999
                        }
                        selectedBooks[book] = count
                        setSelectedBooks({...selectedBooks})
                      }}
                    />
                  </FormField>

                  <Button icon={<AddCircle color='text-xweak' />} onClick={() => {
                    if (!selectedBooks[book]) {
                      selectedBooks[book] = 1
                    } else {
                      selectedBooks[book] += 1
                    }
                    setSelectedBooks({...selectedBooks})
                  }} />

                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody></Table>}

        {(!displayBooks || !displayBooks.length) && (
          <Box direction='row' justify='between' pad='xsmall'
              margin={{ vertical: 'xxsmall' }} background={'light-2'}>
            <Text alignSelf='center' color={'text-xweak'}>{displayBooks && !displayBooks.length ? 'No match!' : 'Loading..'}</Text>
            <Text>
              <Button size='small' icon={<ClearIcon />} disabled />
            </Text>
          </Box>
        )}
      </CardBody>

      <CardFooter pad='medium' background='light-2'></CardFooter>
    </Card>
  )
}

export default SankirtanaAdvForm